<template>
  <v-card shaped :class="loggedIn ? 'mt-2' : ''">
    <v-sheet class="charcoal" shaped>
      <v-card-title :class="textSize + ' pb-0'">
        <v-divider class="charcoal lighten-1" />
        <span class="silver--text mx-2">OPEN BETA</span>
        <v-divider class="charcoal lighten-1" />
      </v-card-title>
      <v-card-text :class="textSizeXSmall + '  pt-0 silver--text'">
        Hey, Lucian here. I am the creator of <strong>BFit</strong>.I started
        <strong>BFit</strong> as a pandemic project, for a
        <strong>selfish reason</strong>. I wanted to have an app that allowed me
        to design and track my workouts in a way that made sense to
        <strong>me</strong>. And I couldn't find it. So I started working on
        one.
        <v-spacer />
        Two years later, I have the app I wanted! But now, I want more from it.
        I think the app can help more people like me. So, I am asking, you,
        fitness enthusiasts, to please help make BFit better by joining our open
        beta testing!
      </v-card-text>
      <v-row justify="center" class="my-4">
        <v-img
          :src="require('@/assets/LucianBadea.jpg')"
          lazy-src="https://picsum.photos/id/11/100/60"
          max-width="500"
          max-height="300"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-row>
      <v-card-actions class="pt-0">
        <v-spacer />
        <BaseActionButton
          class="charcoal progressActive--text"
          icon="mdi-location-enter"
          label="Join BFIT Beta"
          text
          x-large
          @clickedThis="$router.push('/register')"
        />
      </v-card-actions>
    </v-sheet>
    <v-card-title class="pb-0">
      What's in in for you?
    </v-card-title>
    <v-card-text>
      Aside from access to an application that will help you better track and
      design your workouts, you will also be upgraded to
      <strong>BFit PRO</strong> for the duration of the beta test.
    </v-card-text>
    <v-card-title class="pb-0">
      How do I submit feedback?
    </v-card-title>
    <v-card-text>
      You can submit a problem, bug, or feature request by clicking "More" on
      the bottom menu and then clicking
      <span class="accent--text">"Problem?"</span>. When submitting the problem
      report, you will be allowed to use a screenshot, make annotations, add
      comments, etc.
    </v-card-text>
    <FirstTime :show="false" buttonLabel="Join" />
  </v-card>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import slides from '@/json/homepage-slides.json'
const FirstTime = () =>
  import(/* webpackPrefetch: true */ '@/components/pages/FirstTime.vue')

export default {
  components: { FirstTime },
  mixins: [util],
  data: () => ({
    main: 0,
    slides: slides,
    volumeStats: {},
    invites: [],
    reps: {},
    moving: {},
    loaded: false,
    showStats: false
  }),
  beforeMount() {},
  beforeDestroy() {},
  computed: {
    gradient() {
      return 'to top, rgba(220,250,250,.0), rgba(255,240,255,.2)'
    },
    ...appConfig
  },
  methods: {}
}
</script>
