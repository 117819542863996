<template>
  <v-container>
    <Default v-if="loggedIn" />
    <HomeNotLoggedIn v-else />
  </v-container>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
const Default = () =>
  import(/* webpackPrefetch: true */ '@/components/pages/Feed.vue')
const HomeNotLoggedIn = () =>
  import(/* webpackPrefetch: true */ '@/components/pages/HomeNotLoggedIn.vue')
export default {
  components: { Default, HomeNotLoggedIn },
  mixins: [util],
  data: () => ({}),
  beforeMount() {
    // if not logged in in an app
    if (!this.loggedIn && window.ReactNativeWebView != undefined)
      this.$router.push('/login')
  },
  beforeDestroy() {},
  computed: {
    ...appConfig
  },
  methods: {}
}
</script>
