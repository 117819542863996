<template>
  <v-container>
    <v-card class="mt-4">
      <v-card-title class="pt-2 px-2 accent--text display-2">
        BFIT <span class="silver--text body-1 mt-n6 ml-1">R</span>
        <v-divider class="accent lighten-4 ml-n3" />
      </v-card-title>
      <FirstTime />
    </v-card>
  </v-container>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import slides from '@/json/homepage-slides.json'
const FirstTime = () =>
  import(/* webpackPrefetch: true */ '@/components/pages/FirstTime.vue')

export default {
  components: { FirstTime },
  mixins: [util],
  data: () => ({
    main: 0,
    slides: slides,
    volumeStats: {},
    invites: [],
    reps: {},
    moving: {},
    loaded: false,
    showStats: false
  }),
  beforeMount() {},
  beforeDestroy() {},
  computed: {
    gradient() {
      return 'to top, rgba(220,250,250,.0), rgba(255,240,255,.2)'
    },
    ...appConfig
  },
  methods: {}
}
</script>
